const CopyTooltipIcon = ({
  className,
  text = 'Copied',
}: {
  className?: string;
  text?: string;
}): JSX.Element => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='78'
      height='28'
      fill='none'
      viewBox='0 0 78 28'
    >
      <path
        id='bg'
        fill='url(#paint0_linear)'
        fillRule='evenodd'
        d='M12 4C5.373 4 0 9.373 0 16s5.373 12 12 12h54c6.627 0 12-5.373 12-12S72.627 4 66 4H41.408L39.364.496a1 1 0 00-1.728 0L35.592 4H12z'
        clipRule='evenodd'
      ></path>
      <text
        x='50%'
        y='58%'
        className='text-white text-2xs'
        fill='white'
        textAnchor='middle'
        dominantBaseline='central'
      >
        {text}
      </text>

      <defs>
        <linearGradient
          id='paint0_linear'
          x1='78'
          x2='60.192'
          y1='28'
          y2='-21.607'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2BE0FA'></stop>
          <stop offset='1' stopColor='#127392'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export { CopyTooltipIcon };
