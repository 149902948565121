const CopyIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className || 'w-3.5 h-3.5'}
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      viewBox='0 0 24 24'
    >
      <rect width='13' height='13' x='9' y='9' rx='2' ry='2'></rect>
      <path d='M5 15H4a2 2 0 01-2-2V4a2 2 0 012-2h9a2 2 0 012 2v1'></path>
    </svg>
  );
};

export { CopyIcon };
