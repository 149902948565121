export const CopyLinkIcon = ({
  className,
}: {
  className?: string;
}): JSX.Element => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={className ?? 'w-4 h-4'}
      viewBox='0 0 14 14'
    >
      <g clipPath='url(#a)'>
        <path
          fill='currentColor'
          d='M13.06 2.942a3.226 3.226 0 0 0-4.556 0l-.618.618a.316.316 0 0 1-.379.051 3.228 3.228 0 0 0-3.86.529L.944 6.843A3.222 3.222 0 0 0 5.5 11.4l2.703-2.703a3.2 3.2 0 0 0 .867-1.589.653.653 0 0 0-.457-.779.634.634 0 0 0-.778.49c-.06.344-.221.662-.462.914l-.681.681a.318.318 0 0 1-.538-.179 1.961 1.961 0 0 1 .544-1.692l2.703-2.704a1.954 1.954 0 1 1 2.763 2.763L9.46 9.305c-.31.311-.716.507-1.153.556a.651.651 0 0 0-.587.642.635.635 0 0 0 .7.622 3.226 3.226 0 0 0 1.937-.922l2.703-2.704a3.225 3.225 0 0 0 0-4.557ZM5.8 5.645a3.223 3.223 0 0 0-.847 3.056 1.921 1.921 0 0 1-.804 2.1c-.151.103-.319.18-.496.225a1.983 1.983 0 0 1-1.813-.524 1.961 1.961 0 0 1 0-2.762l2.704-2.703a1.948 1.948 0 0 1 1.682-.546.324.324 0 0 1 .18.549l-.605.605Z'
        />
      </g>
      <defs>
        <clipPath id='a'>
          <path d='M0 0h14v14H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};
