import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';

import { BrowserTimeoutCtrl } from '../../utils/BrowserTimeoutCtrl';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { CopyIcon } from '../icons/CopyIcon';
import { CopyLinkIcon } from '../icons/CopyLinkIcon';
import { CopyTooltipIcon } from '../icons/CopyTooltipIcon';

export function CopyButton(props: {
  children: React.ReactNode;
  copiedText: string | (() => string);
  promptText?: string;
  className?: string;
}): JSX.Element {
  const [showCopyTooltip, setShowCopyTooltip] = useState<boolean>(false);
  const [timer] = useState(() => new BrowserTimeoutCtrl());

  const handleCopy = () => {
    let text = '';
    if (typeof props.copiedText === 'function') {
      text = props.copiedText();
    } else {
      text = props.copiedText;
    }
    copy(text, {
      format: 'text/plain',
    });
    setShowCopyTooltip(true);
    timer.clear();
    timer.set(() => {
      setShowCopyTooltip(false);
    }, 3000);
  };

  return (
    <div className='relative flex items-center'>
      <button
        type='button'
        onClick={handleCopy}
        className={props.className ?? 'btn'}
      >
        {props.children}
      </button>

      {showCopyTooltip && (
        <CopyTooltipIcon
          className={`absolute z-5 left-1/2 transform -translate-x-1/2 top-full transition-opacity px-1 ${
            showCopyTooltip ? 'opacity-100' : 'opacity-0'
          }`}
          text={props.promptText || 'Copied'}
        />
      )}
    </div>
  );
}

export const CopyLinkButtonWithPreview = (props: {
  dimensionsClassName?: string;
  ctaVariant?: 'primary' | 'secondary';
  urlToCopy: string;
}): JSX.Element => {
  const [showCopied, setShowCopied] = useState(false);
  const [timer] = useState(() => new BrowserTimeoutCtrl());

  const handleCopy = () => {
    copy(props.urlToCopy, {
      // It's a work around for the bug that when paste the link to some rich content editor (like Gmail),
      // the link text is highlighted in black. Need to figure out what is the root cause.
      format: 'text/plain',
    });
    setShowCopied(true);
    timer.clear();
    timer.set(() => {
      setShowCopied(false);
    }, 3000);
  };

  return (
    <button
      type='button'
      className={`
        ${props.dimensionsClassName ?? 'w-72 h-10'}
        ring-1 ring-secondary rounded-xl
        overflow-hidden relative
        btn flex items-center
      `}
      onClick={handleCopy}
    >
      <span
        className='
          h-full w-full px-2 overflow-hidden whitespace-nowrap overflow-clip
          bg-black
          font-normal text-2xs text-gray-001
          flex items-center
        '
      >
        {props.urlToCopy}
      </span>
      <div
        className={`
          w-22 h-8 rounded-1.5lg absolute right-1
          flex-grow-0 flex-shrink-0
          tracking-wider text-sms font-medium
          flex justify-center items-center gap-2.5
          ${props.ctaVariant === 'secondary' ? 'btn-secondary' : 'btn-primary'}
      `}
      >
        {showCopied ? null : (
          <CopyLinkIcon
            className={`w-5 h-5 ${showCopied ? 'text-primary' : 'text-white'}`}
          />
        )}
        <div className={`text-white`}>{showCopied ? 'Copied!' : 'Copy'}</div>
      </div>
    </button>
  );
};

export function IconCopyButton(props: {
  text: string;
  iconSize?: string;
}): JSX.Element {
  const [showSuccess, setShowSuccess] = useState(false);
  const [timer] = useState(() => new BrowserTimeoutCtrl());

  useEffect(() => {
    return () => {
      timer.clear();
    };
  }, [timer]);

  const handleCopy = () => {
    copy(props.text, {
      format: 'text/plain',
    });
    setShowSuccess(true);
    timer.clear();
    timer.set(() => {
      setShowSuccess(false);
    }, 3000);
  };

  return (
    <div className='flex items-center'>
      {showSuccess ? (
        <span className='text-green-001'>
          <FilledCheckIcon
            className={`fill-current ${props.iconSize ?? 'w-3.5 h-3.5'}`}
          />
        </span>
      ) : (
        <button
          type='button'
          onClick={handleCopy}
          className='btn'
          disabled={showSuccess}
        >
          <CopyIcon className={`${props.iconSize ?? 'w-3.5 h-3.5'}`} />
        </button>
      )}
    </div>
  );
}
